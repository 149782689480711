import React, {useEffect} from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import MemberDisplay from "../components/member-display/member-display"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

export default function HallOfFame({ data }) {

    //HOF Page Data
    const hofPage = data?.wpPage?.hallOfFame

    //Inner Hero Data
    const innerHero = hofPage?.hofInnerHero

    //HOF Members Data
    const hofMemberList = data?.allWpTeam?.nodes

    useEffect(() => {
      AOS.init({
        duration: 2000
      });
      AOS.refresh();
    }, []);
    return (
        <>
        <DefaultLayout data={data}>
        <InnerPageHero innerData={innerHero} />
        <div className="rs-club" style={{paddingTop: "100px"}}>
          <div className="container">
            <h3 className="title-bg">{data.wpPage.title}</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-md-16">
                <div className="rs-club-text">
                  <div
                    dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="5"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MemberDisplay teamMemberList={hofMemberList} />
        </DefaultLayout>
        </>
    )
}

export const query = graphql`
  query ($slug: String!) {
    allWpTeam (filter: {peopleCategories: {nodes: {elemMatch: {name: {eq: "Hall of fame"}}}}})
        {
        nodes {
          slug
          team {
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 617, width: 450)
                }
              }
            }
            number
            position
            teamCoaching
            teamName
            birthday
            yearsOfCoaching
            facebookLink {
              url
            }
            instagramLink {
              url
            }
            linkedinLink {
              url
            }
            twitterLink {
              url
            }
            countryFlag {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 20, height: 10)
                }
              }
            }
          }
          title
        }
    }
    wpPage(slug: { eq: $slug }) {
      id
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      hallOfFame {
        hofInnerHero {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 380)
              }
            }
          }
          leftButton {
            url
            title
          }
          rightButton {
            title
            url
          }
          title
        }
      }
    }
  }
`
